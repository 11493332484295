body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  overflow-y: hidden;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.App{
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  color: white;
}

.images {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
}

.image {
  transition: all 0.3s ease-in;
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(90%);
  filter: brightness(30%);
}
.imageNext {
  transition: all 0.3s ease-in;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  
  filter: grayscale(90%);
  filter: brightness(30%);
  display: hidden;
}
.hidden {
  transition: all 0.3s ease-in;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
}

.content{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
  /* font-family: 'Edu NSW ACT Foundation', cursive; */
  /* font-family: 'Pacifico', cursive; */
  /* font-family: 'Rubik Iso', cursive; */
  /* font-family: 'Source Code Pro', monospace; */
  font-family: 'Ubuntu', sans-serif;  
}

.content h1 {
  font-size: 4rem;
  text-align: center;
}

.links{
  display: flex;
  position: absolute;
  bottom: 5vh;
}

.links a {
  margin-right: 12px;
  color: white;

  transition: all 0.1s ease-in;
}

.links a:hover {
  margin-right: 12px;
  color: rgb(180, 228, 233);
}
